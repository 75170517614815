<template>
  <b-card-body>
    <b-card-title>Telegram Channel</b-card-title>
    <b-form @submit.prevent="createChannel">
      <b-form-group label-for="telegram-token" label="Bot Token" label-class="font-weight-bold">
        <b-form-input @input="handlerInputToken" type="text" placeholder="Insert your Telegram bot token" id="telegram-token" v-model="configuration.token"/>
        <span>You received this token during the Telegram bot creation process.</span><br/>
      </b-form-group>
      <b-form-group label-for="telegram-username" label="Bot Username" label-class="font-weight-bold">
        <b-form-input disabled type="text" placeholder="" id="telegram-username" v-model="configuration.bot_username"/>
      </b-form-group>
      <b-button :disabled="!isCanContinue" type="submit" variant="primary">{{ $t('general.submit') }}</b-button>
    </b-form>
  </b-card-body>
</template>
<script>
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'AddTelegramChannel',
  data() {
    return {
      configuration: {
        token: '',
        bot_username: '',
      },
      timeout: null,
    };
  },
  computed: {
    isCanContinue() {
      const regex = /(\w+):(\w+)/g;
      return regex.test(this.configuration.token);
    },
  },
  methods: {
    createChannel() {
      this.$emit('createChannel', this.configuration);
    },
    handlerInputToken() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        console.log('testing...');
        const regex = /(\w+):(\w+)/g;
        if (regex.test(this.configuration.token)) {
          // Validate token
          this.checkProfile();
        }
      }, 1000);
    },
    async checkProfile() {
      this.$parent.showLoader();
      await channelsAPI.checkTelegramProfile({
        type: 'telegram',
        token: this.configuration.token,
        workspace_id: this.$parent.activeWorkspace._id,
      }).then(async (response) => {
        await popupErrorMessages(response);
        const { profile } = response.data;
        this.configuration.bot_username = profile.username;
      }).catch(() => {});
      this.$parent.hideLoader();
    },
  },
};
</script>
